import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

/**
 * キー毎に独立して存在する状態を扱うフックです。
 *
 * 基本的に `useState<T>()` と同様です。
 * ただし、キーが変化すると 値とセッター関数 はリセットされます。
 *
 * @param key キー
 * @returns 値とセッター関数の組
 */
export function useKeyedState<T>(
    key: string,
): [value: T | undefined, setValue: Dispatch<SetStateAction<T | undefined>>];
/**
 * キー毎に独立して存在する状態を扱うフックです。
 *
 * 基本的に `useState<T>(init)` と同様です。
 * ただし、キーが変化すると 値とセッター関数 はリセットされます。
 *
 * @param key キー
 * @param init 初期値または初期化関数
 * @returns 値とセッター関数の組
 */
export function useKeyedState<T>(
    key: string,
    init: T | (() => T),
): [value: T, setValue: Dispatch<SetStateAction<T>>];

export function useKeyedState<T>(
    key: string,
    init?: T | (() => T),
): [value: T, setValue: Dispatch<SetStateAction<T>>] {
    const ref = useRef<{ key: string; value: T }>();
    if (ref.current === undefined || ref.current.key !== key) {
        const value =
            typeof init === "function" ? (init as () => T)() : (init as T);
        ref.current = { key, value };
    }

    const store = ref.current;
    const [, setRevision] = useState(0);
    const setValue = useCallback(
        (newValueOrUpdate: SetStateAction<T>): void => {
            const oldValue = store.value;
            const newValue =
                typeof newValueOrUpdate === "function"
                    ? (newValueOrUpdate as (prevState: T) => T)(oldValue)
                    : newValueOrUpdate;

            if (newValue !== oldValue) {
                store.value = newValue;
                setRevision((rev) => (rev + 1) & 0xffffff); // 再描画をトリガーする
            }
        },
        [store],
    );

    return [store.value, setValue];
}
